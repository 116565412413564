import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import WhoItsForPage from '../ui/pages/WhoItsForPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const WhoItsFor = props => (
  <>
    <HelmetComponent
      title={metaTags.whoItsForTitle}
      description={metaTags.whoItsFor}
      page="de/who-its-for"
    />
    <AppWrapper {...props} lang="de">
      <WhoItsForPage />
    </AppWrapper>
  </>
)

export default WhoItsFor
